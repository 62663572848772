:root {
    --light-blue: #BDD8EC;
    --dark-blue: #043050;
    --gray: #D4D8DB;
}

.app {
    background-color: var(--gray);
    text-align: center;
    height: 100%;
}

html {
    height: 100%;
    background-color: var(--gray);
    font-family: 'Noto Sans', sans-serif;
    color: var(--dark-blue)
}