.musicCard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
}

.music ul {
    list-style-type: none;
    padding: 0;
}

.music {
    background-color: var(--light-blue);
    border: 4px solid var(--dark-blue);
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 2px 5px 5px gray;
    padding: 30px;
    margin: 30px;
    display: flex;
    flex-direction: column;
}

.music img {
    width: 300px;
    height: 300px;
}

@media screen and (max-width: 1200px) {
    .musicCard {
        flex-direction: column;
    }
}

@media screen and (max-width: 400px) {
    .music img {
        width: 150px;
        height: 150px;
    }
}
