.aboutMe {
    color: var(--dark-blue);
    background-color: var(--light-blue);
    height: 75%;
    width: 75%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 50px auto;
    border: 4px solid var(--dark-blue);
    border-radius: 15px;
    box-shadow: 2px 5px 5px gray;
}

.aboutMe p {
    margin: 30px 50px;
}