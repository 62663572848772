.navbar {
    background-color: var(--light-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dark-blue);
    font-size: 1.5rem;
    font-weight: bold;
    box-shadow: 2px 2px 2px gray;
    border-bottom: 2px solid var(--dark-blue);
    font-family: 'Merriweather', serif;
    width: 100%;
}

.navbar svg {
    min-width: 200px;
    min-height: 200px;
}

nav ul {
    display: flex;
    list-style: none;
    align-items: center;
}

nav li {
    margin: 15px;
    padding: 10px;
    border-radius: 15px;
    background-color: var(--gray);
    box-shadow: 2px 5px 2px gray;
    border: 2px solid gray;
}

nav a {
    text-decoration: none;
    color: var(--dark-blue);
}

nav li:hover {
    box-shadow: none;
    transition: all 0.5s;
}

@media screen and (max-width: 815px) {
    .navbar {
        font-size: 1rem;
    }

    .navbar ul {
        padding: 0;
    }
}

@media screen and (max-width: 650px) {
    .navbar {
        font-size: .75rem;
    }

    .navbar li {
        margin: 10px;
    }

    nav ul {
        flex-direction: column;
    }
}

