.home {
    width: auto;
    background-color: var(--gray);
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner img {
    height: 50%;
    width: 50%;
}

.banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px;
    background-color: var(--light-blue);
    padding: 20px;
    box-shadow: 2px 5px 5px gray;
    border: 4px solid var(--dark-blue);
    border-radius: 15px;
    height: 80%;
    width: 60%;
    
}

.banner-text {
    color: var(--dark-blue);
    font-size: 2rem;
}

@media screen and (max-width: 650px) {
    .banner {
        flex-direction: column;
    }

    .banner-text {
        font-size: 1rem;
    }
}