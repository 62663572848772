.project {
    display: flex;
    height: 300px;
    width: 400px;
    border: 4px solid var(--dark-blue);
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 2px 5px 5px gray;
    flex-direction: column;
    background-color: var(--light-blue);
    margin: 20px;
}

.project:hover {
    animation: .3s 1 cardshake;
}

.project ul {
    list-style-type: none;
}

.project li {
    margin: 15px;
}

.project a {
    text-decoration: none;
}

.project a:visited {
    color: var(--dark-blue)
}

.project a:hover {
    color: var(--gray);
    background-color: var(--dark-blue);
    border-radius: 5px;
    padding: 2px;
}

.projectsList {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

@keyframes cardshake {
    30%{
        translate: 1px;
    }

    60% {
        translate: -2px;
    }

    100% {
        translate: 1px
    }
}